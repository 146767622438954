'use strict';

angular.module('app').
    service('AuthService', ['$rootScope', '$http', '$log', '$location',
        function ($rootScope, $http, $log, $location) {

            $rootScope.global_app = {
                uri: $location.protocol() + "://" + $location.host() + ":" + $location.port()
            }
console.log($rootScope.global_app.uri);

            this.check = function (user) {
                var promise = $http.get($rootScope.global_app.uri + '/auth/login').then(function (response) {
                    $rootScope.isAuthenticated = (response.data.status == 'success') ? true : false;
                    return response.data;
                }, function (error) {
                    //error
                    $log.info(error);
                })
                return promise;
            };

            this.check_okta = function () {
                    var promise = $http.get('https://tctmd.okta.com/api/v1/sessions/me/',{withCredentials: true}).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info(error);
                })
                return promise;
            };

            this.okfromokta = function (formData) {

                var promise = $http.post($rootScope.global_app.uri + '/auth/okfromokta',formData).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info(error);
                })
                return promise;
            };



            this.checkxx = function (user) {
                var promise = $http.get('auth/login').then(function (response) {

                    $rootScope.isAuthenticated = response.data.success;
                    return response.data;
                }, function (error) {
                    //error
                    $log.info(error);
                    return false;
                })
                return promise;
            };

            this.okta_assert_login = function (url) {
                var promise = $http.get(url, {withCredentials: true}).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info(error);
                })
                return promise;
            };

            this.login = function (user) {
                if (user.token) {
                    var credentials = {
                        'token': user.token
                    }
                } else {
                    var credentials = {
                        'username': user.username,
                        'password': user.password
                    }
                }
                var promise = $http.post($rootScope.global_app.uri + '/auth/login', credentials).then(function (response) {

                    if (response.data == 'These credentials do not match our records.') {
                        alert('Invalid Login. Please try again.')
                    }

                    //console.log('authservice',response.data)
                    $rootScope.isAuthenticated = (response.data.status == 'success') ? true : false;

                    return response.data;

                }, function (error) {
                    //error
                    $log.info(error);
                })
                return promise;
            };

            this.logout = function (session_id) {
                var promise = $http.get($rootScope.global_app.uri + '/auth/logout/' + session_id ).then(function (response) {
                    $rootScope.isAuthenticated = response.data.logout;
                    return response.data;

                }, function (error) {
                    //error
                    $log.info(error);
                })
                return promise;
            };
        }
    ]
);



